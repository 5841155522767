<app-header></app-header>

<bit-container>
  <p bitTypography="body1">{{ "preferencesDesc" | i18n }}</p>
  <form [formGroup]="form" [bitSubmit]="submit" class="tw-w-1/2">
    <bit-callout type="info" *ngIf="vaultTimeoutPolicyCallout | async as policy">
      <span *ngIf="policy.timeout && policy.action">
        {{
          "vaultTimeoutPolicyWithActionInEffect"
            | i18n: policy.timeout.hours : policy.timeout.minutes : (policy.action | i18n)
        }}
      </span>
      <span *ngIf="policy.timeout && !policy.action">
        {{ "vaultTimeoutPolicyInEffect" | i18n: policy.timeout.hours : policy.timeout.minutes }}
      </span>
      <span *ngIf="!policy.timeout && policy.action">
        {{ "vaultTimeoutActionPolicyInEffect" | i18n: (policy.action | i18n) }}
      </span>
    </bit-callout>
    <auth-vault-timeout-input
      [vaultTimeoutOptions]="vaultTimeoutOptions"
      [formControl]="form.controls.vaultTimeout"
      ngDefaultControl
    >
    </auth-vault-timeout-input>
    <ng-container *ngIf="availableVaultTimeoutActions$ | async as availableVaultTimeoutActions">
      <bit-radio-group
        formControlName="vaultTimeoutAction"
        *ngIf="availableVaultTimeoutActions.length > 1"
      >
        <bit-label>{{ "vaultTimeoutAction" | i18n }}</bit-label>
        <bit-radio-button
          *ngIf="availableVaultTimeoutActions.includes(VaultTimeoutAction.Lock)"
          id="vaultTimeoutActionLock"
          [value]="VaultTimeoutAction.Lock"
        >
          <bit-label>{{ "lock" | i18n }}</bit-label>
          <bit-hint>{{ "vaultTimeoutActionLockDesc" | i18n }}</bit-hint>
        </bit-radio-button>
        <bit-radio-button
          *ngIf="availableVaultTimeoutActions.includes(VaultTimeoutAction.LogOut)"
          id="vaultTimeoutActionLogOut"
          [value]="VaultTimeoutAction.LogOut"
        >
          <bit-label>{{ "logOut" | i18n }}</bit-label>
          <bit-hint>{{ "vaultTimeoutActionLogOutDesc" | i18n }}</bit-hint>
        </bit-radio-button>
      </bit-radio-group>
    </ng-container>
    <bit-form-field>
      <bit-label
        >{{ "language" | i18n }}
        <a
          bitLink
          class="tw-float-right"
          href="https://bitwarden.com/help/localization/"
          target="_blank"
          rel="noreferrer"
          appA11yTitle="{{ 'learnMore' | i18n }}"
          slot="end"
        >
          <i class="bwi bwi-question-circle" aria-hidden="true"></i>
        </a>
      </bit-label>
      <bit-select formControlName="locale" id="locale">
        <bit-option *ngFor="let o of localeOptions" [value]="o.value" [label]="o.name"></bit-option>
      </bit-select>
      <bit-hint>{{ "languageDesc" | i18n }}</bit-hint>
    </bit-form-field>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="enableFavicons" />
      <bit-label
        >{{ "enableFavicon" | i18n }}
        <a
          bitLink
          href="https://bitwarden.com/help/website-icons/"
          target="_blank"
          rel="noreferrer"
          appA11yTitle="{{ 'learnMore' | i18n }}"
          slot="end"
        >
          <i class="bwi bwi-question-circle" aria-hidden="true"></i>
        </a>
      </bit-label>
      <bit-hint>{{ "faviconDesc" | i18n }}</bit-hint>
    </bit-form-control>
    <bit-form-field>
      <bit-label>{{ "theme" | i18n }}</bit-label>
      <bit-select formControlName="theme" id="theme">
        <bit-option *ngFor="let o of themeOptions" [value]="o.value" [label]="o.name"></bit-option>
      </bit-select>
      <bit-hint>{{ "themeDesc" | i18n }}</bit-hint>
    </bit-form-field>
    <button bitButton bitFormButton type="submit" buttonType="primary">{{ "save" | i18n }}</button>
  </form>
</bit-container>
